@import 'import';
$root: '.ModalContent';

#{$root} {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  min-height: inherit;

  text-align: center;

  h1 {
    @include font($size: rem(22), $weight: 'bold');
  }

  p {
    @include font($size: rem(15));
  }

  &__body {
    @include make-container();

    flex: 1;
    padding-top: 60px;
    padding-bottom: 45px;
  }

  &__footer {
    @include make-container();

    flex: 0 0;

    margin-top: auto;
    padding-top: 20px;
    padding-bottom: 20px;

    color: $lightgrey2;

    background-color: #f1eeee;

    a {
      color: currentcolor;
    }

    > * {
      margin: 0;
    }
  }

  &.-centered {
    #{$root}__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  // IE hack - cannot fill height vertically using flex when parent sized with min-height
  // So revert to normal flow and do not stick button to the bottom
  @media all and (-ms-high-contrast: none) {
    display: block;
  }

  @include media-breakpoint-up(md) {
    h1 {
      font-size: rem(30);
    }
  }
}
