@import '../base/functions';

@mixin font(
  $size: null,
  $name: $font-family-base,
  $weight: null,
  $color: null,
  $line-height: $line-height-base,
  $style: null,
  $spacing: null,
  $align: null,
  $transform: null,
  $decoration: null
) {
  @if $size != null {
    font-size: $size;
  }

  @if $name != null {
    font-family: map-get-default($fonts, $name);
  }

  @if $weight != null {
    font-weight: map-get-default($font-weights, $weight);
  }

  @if $line-height != null {
    line-height: $line-height;
  }

  @if $color != null {
    color: $color;
  }

  @if $style != null {
    font-style: $style;
  }

  @if $spacing != null {
    letter-spacing: $spacing;
  }

  @if $align != null {
    text-align: $align;
  }

  @if $transform != null {
    text-transform: $transform;
  }

  @if $decoration != null {
    text-decoration: $decoration;
  }
}

@mixin make-heading(
  $size,
  $name: $headings-font-family,
  $weight: map-get($font-weights, bold),
  $color: $headings-color
) {
  $size: map-get-default($heading-sizes, $size);

  margin-bottom: $headings-margin-bottom;

  @include font($size, $name, $weight, $color, $headings-line-height);
}

@mixin strikethrough-price() {
  .price-with-discount {
    .amount {
      @include font($size: rem(30), $weight: 'bold', $line-height: 1, $color: $vv-red);
    }

    .amount.same-line-prices {
      @include font($size: rem(24), $weight: 'bold', $line-height: 1, $color: $vv-red);

      margin-right: 2px;
    }

    .original-amount {
      @include font($size: rem(22), $weight: 'normal', $line-height: 1, $color: currentcolor);
    }
  }
}
