// Override bootstrap mixin, to allow full gutter width left/right padding on container
@mixin make-container() {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;
}

@mixin make-container-max-width() {
  @include make-container();

  max-width: $container-max-width;
}

@mixin make-container-padded($padding: $grid-gutter-width) {
  $padding: $padding * 1px;

  @include make-container-max-width();

  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;

  @include media-breakpoint-up(xl) {
    padding-right: $padding;
    padding-left: $padding;
  }
}
